<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="energy" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagMinMax"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '800px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Ventil Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('minmax')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Ventil-Einstellungen">
                        <Panel header="Hauptschalter">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-power" text="<p>Der Hauptschalter aktiviert oder deaktiviert dieses Ventil übergeordnet. Wenn deaktiviert, sind alle nachfolgenden Einstellungen unrelevant.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-checkbox col justify-content-center">
                                        <InputSwitch v-model="this.getDiagDataMinMax('valveEnable').value" /><span class="ml-2">{{ this.getDiagDataMinMax('valveEnable').label }}</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Gruppenstart-Einstellungen" class="mt-3">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-power" text="<p>Der Gruppenstart verbindet das Ventil mit einer Gruppe (Gruppenprogramm), um automatisch nach den Bedingungen der Gruppe starten zu können. Die <b>Zeit Gruppenstart</b> gibt an, wie lange das Ventil bewässern soll und die Gruppenauswahl verbindet das Ventil mit einer gewünschten Gruppe. Ist der Schalter <b>Gruppenstart</b> deaktiviert, befindet sich das Ventil in keiner Gruppe.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <div class="flex justify-content-center mt-2 mb-2">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataMinMax('groupStart').value" /><span class="ml-2">{{ this.getDiagDataMinMax('groupStart').label }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="runtimeGrpStart" ref="runtimeGrpStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('runtimeGrpStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="runtimeGrpStart" class="mr-2">{{ this.getDiagDataMinMax('runtimeGrpStart').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <Dropdown v-model="this.getDiagDataMinMax('assignedGroup').value" :options="getIrrigationGroups" optionLabel="label" optionValue="value" placeholder="Gruppe wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Handstart-Einstellungen" class="mt-3">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-water" text="<p>Der Handstart ist als manuelle Startbedingung für das Ventil gedacht. Die <b>Zeit Handstart</b> gibt die Bewässerungszeit für den Handstart an, das <b>Düngerrezept</b> das gewünschte Rezept und der Schalter <b>Handstart</b> startet das Ventil nach betätigen der <b>Speichern</b> Taste des Dialogs. Der Handstart kann jederzeit wieder deaktiviert werden, wird aber nach Beendigung des Handstarts automatisch wieder deaktiviert. Ein Handstart hat Priorität gegenüber angemeldeter Gruppen. Jedoch muss, sofern eine Gruppe gerade aktiv ist wenn ein Handstart gestartet wird, die Gruppe zuerst fertig bewässern. Erst dann erfolgt der Handstart des Ventils.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <div class="flex justify-content-center mt-2 mb-2">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataMinMax('manualStart').value" /><span class="ml-2">{{ this.getDiagDataMinMax('manualStart').label }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="runtimeManualStart" ref="runtimeManualStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('runtimeManualStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="runtimeManualStart" class="mr-2">{{ this.getDiagDataMinMax('runtimeManualStart').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagDataMinMax('fertiSelect').value" :options="getFertilizer" optionLabel="label" optionValue="value" placeholder="Düngerezept wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Kapazität (Netzbelastung)" class="mt-3">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-water" text="<p>Die Kapazität gibt an, wieviel Prozent der Systemleistung (meist Pumpenleistung) dieses Ventil benötigt.</p><p>Bsp.: die Systempumpe schafft ca. 20 m3/h -> 20000 (l/h) / 60 (Minuten) = 334 l/min = 100%. 3000 Tropfer mit 4 Liter/h = 200 l/min -> 200 l/min / 334 l/min = 0,60 = 60 % Netzbelastung.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-4">
                                    <span class="p-float-label">
                                        <InputNumber id="capacity" ref="capacity" :min="0.1" :max="100"
                                            v-model="this.getDiagDataMinMax('capacity').value"
                                            :suffix="' ' + this.getDiagDataMinMax('capacity').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="capacity" class="mr-2">{{ this.getDiagDataMinMax('capacity').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Freigabe Gruppe">
                        <Panel header="Freigabezeiten" class="mt-2">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-time-fast" text="<p>Innerhalb der Freigabezeit ist ein Gruppenstart für das Ventil erlaubt.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('intervalTimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('intervalTimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('intervalTimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="intervalTimeSetStart" ref="intervalTimeSetStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataMinMax('intervalTimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">{{ this.getDiagDataMinMax('intervalTimeSetStart').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataMinMax('intervalTimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataMinMax('intervalTimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataMinMax('intervalTimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="intervalTimeSetEnd" ref="intervalTimeSetEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataMinMax('intervalTimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="intervalTimeSetEnd">{{ this.getDiagDataMinMax('intervalTimeSetEnd').label }}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Wassermengen">
                        <Panel header="Aktueller Verbrauch">
                            <template #icons>
                                <i class="fi fi-rr-water bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="OUT_DripperToday" ref="OUT_DripperToday" :min="0.1" :max="100"
                                            v-model="this.getModuleValue('OUT_DripperToday').value"
                                            :suffix="' ' + this.getModuleValue('OUT_DripperToday').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" :disabled="true" />
                                        <label for="OUT_DripperToday" class="mr-2">{{ this.getModuleValue('OUT_DripperToday').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="OUT_DripperYesterday" ref="OUT_DripperYesterday" :min="1" :max="100000"
                                            v-model="this.getModuleValue('OUT_DripperYesterday').value"
                                            :suffix="' ' + this.getModuleValue('OUT_DripperYesterday').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" :disabled="true" />
                                        <label for="OUT_DripperYesterday" class="mr-2">{{ this.getModuleValue('OUT_DripperYesterday').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="OUT_WaterCountToday" ref="OUT_WaterCountToday" :min="0.1" :max="100"
                                            v-model="this.getModuleValue('OUT_WaterCountToday').value"
                                            :suffix="' ' + this.getModuleValue('OUT_WaterCountToday').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" :disabled="true" />
                                        <label for="OUT_WaterCountToday" class="mr-2">{{ this.getModuleValue('OUT_WaterCountToday').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="OUT_WaterCountYesterday" ref="OUT_WaterCountYesterday" :min="1" :max="100000"
                                            v-model="this.getModuleValue('OUT_WaterCountYesterday').value"
                                            :suffix="' ' + this.getModuleValue('OUT_WaterCountYesterday').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" :disabled="true" />
                                        <label for="OUT_WaterCountYesterday" class="mr-2">{{ this.getModuleValue('OUT_WaterCountYesterday').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Tropfer Einstellungen" class="mt-3">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-water" text="<p>Der Tropfer Typ wird normal in Liter/Stunde l/h angegeben und die Menge soll ungefähr die Anzahl der montierten Tropfer sein.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="dripperType" ref="dripperType" :min="0.1" :max="100"
                                            v-model="this.getDiagDataMinMax('dripperType').value"
                                            :suffix="' ' + this.getDiagDataMinMax('dripperType').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="dripperType" class="mr-2">{{ this.getDiagDataMinMax('dripperType').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="dripperCount" ref="dripperCount" :min="1" :max="100000"
                                            v-model="this.getDiagDataMinMax('dripperCount').value"
                                            :suffix="' ' + this.getDiagDataMinMax('dripperCount').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="dripperCount" class="mr-2">{{ this.getDiagDataMinMax('dripperCount').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service" v-if="isMaster">
                        <Panel header="Identität">
                            <template #icons>
                                <i class="fi fi-rr-water bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputText id="name" ref="name" v-model="this.getDiagDataMinMax('name').value" locale="de-DE" class="nice-font p-inputtext-lg" />
                                        <label for="name" class="mr-2">Bezeichnung</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="valveIndex" ref="valveIndex" :min="-1" :max="100000"
                                            v-model="this.getDiagDataMinMax('valveIndex').value"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="valveIndex" class="mr-2">{{ this.getDiagDataMinMax('valveIndex').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Verzögerung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-water bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="valveOnDelay" ref="valveOnDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataMinMax('valveOnDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="valveOnDelay" class="mr-2">{{ this.getDiagDataMinMax('valveOnDelay').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json"
                    @diagAbort="diagToggleMinMax"
                    @diagSave="saveDiag('minmax')"
                    @import="($value) => {this.nodeDataMinMax = $value}"
                    :exportObject="this.nodeDataMinMax"
                ></widgetDialogFooter>
            </template>
        </Dialog>
        <OverlayPanel ref="misc" appendTo="body" @show="focusElement" :dismissable="false">
        </OverlayPanel>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '40vw' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Schirmregler">
                        <Panel header="Schaltschwellen">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMin" ref="schaltschwelleMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMin" class="mr-2">{{ getDiagDataCfg('schaltschwelleMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="schaltschwelleMax" ref="schaltschwelleMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('schaltschwelleMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('schaltschwelleMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="schaltschwelleMax" class="mr-2">{{ getDiagDataCfg('schaltschwelleMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Motor-Einstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-heat bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="laufzeit1" ref="laufzeit1" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('laufzeit1').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="laufzeit1" class="mr-2">Schirm Laufzeit</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="reglerart" ref="reglerart" :min="0" :max="100"
                                            v-model="getDiagDataCfg('reglerart').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('reglerart').unit}`"
                                            :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="reglerart" class="mr-2">{{ getDiagDataCfg('reglerart').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="radiationFilterTime" ref="radiationFilterTime" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('radiationFilterTime').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('radiationFilterTime').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="radiationFilterTime" class="mr-2">{{ getDiagDataCfg('radiationFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputMask id="modeDelay" ref="modeDelay" mask="99:99:99"
                                            placeholder="" slotChar="hh:mm:ss"
                                            v-model="this.getDiagDataCfg('modeDelay').value"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="modeDelay" class="mr-2">Modus-Verzögerung (Umschaltung)</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <div class="field">
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="diagToggleMisc" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveDiag('misc')" />
                    </div>
                </div>
            </template>
        </Dialog>

        <!-- STANDARD-STYLE -->
        <div v-if="!isLoading && (content.style.template === 'standard' || !content.style.template)">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="hidden sm:flex mt-1 ml-2">
                        <widget-mini-progress label="Fortschritt" :value="getModuleValue('OUT_Progress')"></widget-mini-progress>
                    </div>
                    <div class="flex mt-1 ml-2">

                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="operateState" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :title="'Bedarf/Betrieb'" :value1="this.getModuleValue('OUT_ValveRequest')" :value2="this.getModuleValue('OUT_Valve')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Dauer/Restdauer'" :value1="this.getModuleValue('OUT_Runtime')" :value2="this.getModuleValue('OUT_Resttime')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Gruppe/Gießdauer'" :value1="this.getModuleValue('OUT_AssignedGrpStr')" :value2="this.getModuleValue('OUT_RuntimeGrpCalc')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Starts heute/gestern'" :value1="this.getModuleValue('OUT_StartsToday')" :value2="this.getModuleValue('OUT_StartsYesterday')"></Widget_rowValues2>
                        <Widget_rowValues2 :title="'Menge heute/gestern'" :value1="this.getModuleValue('OUT_WaterCountToday')" :value2="this.getModuleValue('OUT_WaterCountYesterday')"></Widget_rowValues2>
                    </ul>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-column w-full">
                            <div class="flex-1 align-items-center justify-content-center mr-3">
                                <ul class="widget-list">
                                    <Widget_rowValues2 :title="'Freigabe'" :value1="this.getModuleValue('CFG.intervalTimeSetStart')" :value2="this.getModuleValue('CFG.intervalTimeSetEnd')" :value1Timemode="this.getModuleValue('CFG.intervalTimeModeStart')" :value2Timemode="this.getModuleValue('CFG.intervalTimeModeEnd')"></Widget_rowValues2>
                                    <Widget_rowValues2 :title="'Letzter Start/Ende'" :value1="this.getModuleValue('OUT_LastStartDt')" :value2="this.getModuleValue('OUT_LastEndDt')"></Widget_rowValues2>
                                </ul>
                            </div>
                        </div>
                        <div class="flex-column w-full">
                            <div class="flex-1 align-items-center justify-content-center mt-3">
                                <button-enat title="Einstellungen" subtitle="Allgemeine Einstellungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="cobalt"
                                    @click="toggleOP($event, 'minmax')" >
                                </button-enat>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <!-- COMPACT-STYLE -->
        <div v-if="!isLoading && content.style.template === 'compact'">
            <div class="flex justify-content-between align-items-center p-0">
                <div class="flex justify-content-start align-items-center p-0">
                    <div class="hidden sm:flex mt-1 ml-2">
                        <widget-mini-progress label="Fortschritt" :value="getModuleValue('OUT_Progress')"></widget-mini-progress>
                    </div>
                    <div class="flex mt-1 ml-2">

                    </div>
                    <div class="flex flex-column mt-3">

                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header-compact :value="operateState" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex justify-content-center mt-1 mb-1">
                <div class="flex flex-column">
                    <div class="flex mt-1">
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Widget_singleValue :title="'Bedarf/Betrieb'" :value="this.getModuleValue('CFG.runtimeGrpStart')"></Widget_singleValue>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Widget_singleValue :title="'Bedarf/Betrieb'" :value="this.getModuleValue('OUT_Progress')"></Widget_singleValue>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Widget_singleValue :title="'Bedarf/Betrieb'" :value="this.getModuleValue('OUT_Runtime')"></Widget_singleValue>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center">
                            <Widget_singleValue :title="'Bedarf/Betrieb'" :value="this.getModuleValue('OUT_StartsToday')"></Widget_singleValue>
                        </div>
                    </div>
                </div>
            </div>
            <textbox-enat title="Status" :value="currentStatusStr" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_Valve').icon" :node="getModuleValue('OUT_Valve')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import Widget_singleValue from '@/components/fragments/widget_singleValue.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniProgress from '@/components/fragments/widgetMiniProgress.vue';
import widgetHeaderCompact from '@/components/fragments/widgetHeaderCompact.vue';
// import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
// import widgetTimeSet from '@/components/fragments/widgetTimeSet.vue';
import moduleDialogIconHelp from '@/components/fragments/moduleDialogIconHelp.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';

export default defineComponent({
    name: 'widgetIrrigationValve',
    setup() { },
    components: {
        Widget_rowValues2,
        Widget_singleValue,
        dialogChartSingle,
        widgetMiniProgress,
        widgetHeaderCompact,
        widgetDialogFooter,
        moduleDialogIconHelp,
        moduleIconEnat,
        widgetTitleBar,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getFertilizer: 'types/getFertilizer',
            getIrrigationGroups: 'types/getIrrigationGroups',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            alarmCount = 0;
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        orientationStr1: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 1);
        },
        orientationStr2: function () {
            return helpers.ventOrientation(this.getModuleValue('CFG.ausrichtung').value, 2);
        },
        currentStatusStr: function () {
            return this.getModuleValue('OUT_StatusStr').value;
        },
        currentPositionFloat1: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_1_Aktuell').value);
        },
        currentPositionFloat2: function () {
            return parseFloat(this.getModuleValue('OUT_Stellung_2_Aktuell').value);
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        periodIndex: function () {
            return this.getModuleValue('OUT_AktPeriodeHzg.PeriodIndex').value;
        },
        operateState: function () {
            if (this.getModuleValue('OUT_ValveState').value === 0) return '🔴Aus';
            else if (this.getModuleValue('OUT_ValveState').value === 1 || this.getModuleValue('OUT_ValveState').value === 2) return '🟡Bedarf';
            else if (this.getModuleValue('OUT_ValveState').value === 11 || this.getModuleValue('OUT_ValveState').value === 12) return '🟢Ein';
            else if (this.getModuleValue('OUT_ValveState').value === 91 || this.getModuleValue('OUT_ValveState').value === 92) return '⚪Fertig';
            else return '🔴Aus';
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            saveWait: false,
            dialogLoading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            nodeData: [],
            nodeDataOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            nodeDataMinMax: [],
            nodeDataMinMaxOld: [],
            nodeDataEnergy: [],
            nodeDataEnergyOld: [],
            nodeDataShading: [],
            nodeDataShadingOld: [],
            nodeDataPeriods: [],
            nodeDataPeriodsOld: [],
            showDiagName: false,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            lala: null,
            showDiagEnergy: false,
            showDiagShading: false,
            showDiagMisc: false,
            showDiagMinMax: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        getDiagData(key) {
            if (this.nodeData.length > 0) {
                const result = this.nodeData.find(entry => entry.key2 === key);
                return result;
            }
            return null;
        },
        getDiagDataPeriods(index, displayName) {
            if (this.nodeDataPeriods.length > 0 && index >= 0) {
                const result = this.nodeDataPeriods[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataEnergy(displayName) {
            if (this.nodeDataEnergy.length > 0 && displayName) {
                const result = this.nodeDataEnergy.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataShading(displayName) {
            if (this.nodeDataShading.length > 0 && displayName) {
                const result = this.nodeDataShading.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataMinMax(displayName) {
            if (this.nodeDataMinMax.length > 0 && displayName) {
                const result = this.nodeDataMinMax.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfgOld = [];
            this.nodeDataPeriods = [];
            this.nodeDataPeriodsOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                return helpers.returnChild(this.nodeEntry, childStr);
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            return helpers.returnParameter(node, key, parameter);
        },
        returnParameterValue(node, key, parameter, unit) {
            return helpers.returnParameterValue(node, key, parameter, unit);
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.this.getModuleValueStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'minmax') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataMinMax = JSON.parse(JSON.stringify(response));
                            this.nodeDataMinMaxOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataMinMax);
                            console.log(this.nodeDataMinMaxOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMinMax = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagToggleEnergy() {
            if (this.showDiagEnergy) {
                this.showDiagEnergy = false;
            } else {
                this.showDiagEnergy = true;
            }
            this.hideOP();
        },
        diagToggleShading() {
            if (this.showDiagShading) {
                this.showDiagShading = false;
            } else {
                this.showDiagShading = true;
            }
            this.hideOP();
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'minmax') {
                    console.log(this.nodeDataMinMax);
                    console.log(this.nodeDataMinMaxOld);
                    const flatHzg = this.nodeDataMinMax.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataMinMaxOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'minmax') this.showDiagMinMax = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'minmax') this.showDiagMinMax = false;
                    if (diag === 'misc') this.showDiagMisc = false;
                    this.hideOP();
                }
            }
        },
        toogleNameDiag(name) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.showDiagName = true;
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    obj2 = this.nodeDataPeriods[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            this.hideNameDiag();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
</style>